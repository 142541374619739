import React, {
	useEffect,
	useState,
	useRef,
	useMemo,
	useCallback,
} from "react";
import { LicensePlateTemplateProps } from "./types";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import SvgIcon from "@material-ui/core/SvgIcon";
import { EmailShareButton } from "react-share";
import MomenttrackIdTag from "../../core/components/MomenttrackIdTag";
import QrCodeInput from "../../core/components/QrCodeInput";
import ProductLinkDialog from "./ProductLinkDialog";
import DateTag from "../../core/components/DateTag";
import Product from "../../core/components/Product";
import PlaceEle from "../../core/components/Place";
import {
	ClassicUploader,
	FileUploaderRef,
} from "../../core/components/FileUploader";
import LicensePlateActivityLogs, {
	LicensePlateActivityLogsApi,
} from "./LicensePlateActivityLogs";
import {
	lookupLicensePlate,
	getLicensePlate,
	addLicensePlateNotes,
	updateLicensePlate,
	LicensePlate,
	generateSheets,
	LicensePlateActivityLog,
} from "../../core/system/license-plates";
import { lookupPlace, getPlace, Place } from "../../core/system/places";
import { useAuthentication } from "../../core/providers/AuthenticationProvider";
import { useHistory, useLocation } from "react-router-dom";
import useNotify from "../../core/hooks/use-notify";
import parseId from "../../core/system/utils/parse-momenttrack-id";
import { ReactComponent as ProductIconSvg } from "../../assets/icons/product.svg";
import EmbeddedContentDialog, {
	EmbeddedContentDialogApi,
} from "../../core/components/EmbeddedContentDialog";
import { Product as IProduct } from "../../core/system/products";
import { Place as IPlace } from "../../core/system/places";
import { useSearchParams } from "../../core/hooks/useSearchParams";
import { Icon, IconButton, InputAdornment } from "@material-ui/core";
import QrCodeScanner from "../../core/components/QrCodeScanner";

const LicensePlateTemplate: React.FC<LicensePlateTemplateProps> = props => {
	const { licensePlateId } = props;
	const attachmentUploader = useRef<FileUploaderRef>(null);
	const activityLogs = useRef<LicensePlateActivityLogsApi>(null);
	const [licensePlate, setLicensePlate] = useState<LicensePlate | null>(null);
	const [placeToLink, setPlaceToLink] = useState<Place | undefined>();
	const [loading, setLoading] = useState(false);
	const [pn, setPn] = useState("");
	const [sn, setSn] = useState("");
	const [notes, setNotes] = useState("");
	const [isn, setIsn] = useState("");
	const [currentUser] = useAuthentication();
	const history = useHistory();
	const currentLocation = useLocation();
	const notify = useNotify();
	const [productInfo, setProductInfo] = useState<IProduct | null>(null);
	const [placeInfo, setPlaceInfo] = useState<IPlace | null>(null);
	const [logs, setLogs] = useState<LicensePlateActivityLog[] | null>(null);
	const [pdfURL, setPdfURL] = useState<string | null>(null);
	const pdfSheetsDialog = useRef<EmbeddedContentDialogApi>(null);

	const attachmentsConfig = useMemo(
		() => ({
			s3_prefix: `uploads/org/${currentUser?.organization_id}/lp/${
				licensePlate?.id
			}_${licensePlate?.lp_id.slice(-5)}`,
		}),
		[licensePlate, currentUser]
	);

	const handleScanToLink = (result: string) => {
		const idInfo = parseId(result);
		if (idInfo.type === "place") {
			setLoading(true);
			lookupPlace(idInfo.id)
				.then(lookupResult => getPlace(lookupResult.id))
				.then(setPlaceToLink)
				.then(() => {
					setLoading(false);
				})
				.catch(err => {
					setLoading(false);
					notify(err);
				});
		} else notify("Please use a valid place QR code.");
	};

	const handleAttachment = async () => {
		try {
			setLoading(true);
			const url = await attachmentUploader.current?.startUpload();
			if (!url) return;

			attachmentUploader.current?.clearSelection();
			await handleAddNotes({ message: `<File>${url}</File>` });
		} catch (err) {
			notify(err as Error);
		} finally {
			setLoading(false);
		}
	};

	const handleAddNotes = useCallback(
		async ({
			message,
			external_serial_number,
		}: {
			message: string;
			external_serial_number?: string;
		}) => {
			setLoading(true);
			try {
				message = message.trim();
				if (!licensePlate?.id) return;

				let updatedLicensePlate;
				if (external_serial_number && external_serial_number?.length > 0) {
					updatedLicensePlate = await updateLicensePlate(licensePlate?.id, {
						external_serial_number,
					});
				}
				await addLicensePlateNotes(licensePlate?.id, message);
				activityLogs.current?.insertLog({
					message,
					activity: "NOTES",
					user: {
						id: currentUser?.id || 0,
						first_name: currentUser?.first_name || "",
						person_id: currentUser?.person_id || "",
					},
					meta: null,
					created_at: new Date().toISOString().replace(/Z$/i, ""),
				});
				if (updatedLicensePlate) {
					setLicensePlate(updatedLicensePlate);
				}
				notify("Product updated successfully");
			} catch (err) {
				notify(err as Error);
			} finally {
				setLoading(false);
			}
		},
		[
			currentUser?.first_name,
			currentUser?.id,
			currentUser?.person_id,
			licensePlate?.id,
			notify,
		]
	);

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);

				const lookupResult = await lookupLicensePlate(licensePlateId);
				const licensePlate = await getLicensePlate(lookupResult.id);
				setLicensePlate(licensePlate);
			} catch (err) {
				history.replace(`/${licensePlateId}/legacy`);
			} finally {
				setLoading(false);
			}
		})();
	}, [currentUser, history, licensePlateId]);

	const handleGetDialogContent = (url: string) => {
		setPdfURL(url);
	};

	const handleShareButton = () => {
		if (!licensePlateId || !productInfo || !placeInfo || !logs || !licensePlate)
			return;

		pdfSheetsDialog.current?.openDialog(() =>
			generateSheets({
				licensePlateId,
				productInfo,
				placeInfo,
				licensePlate,
				logs,
			})
		);
	};

	const handleGetLogs = (logs: LicensePlateActivityLog[]) => {
		setLogs(logs);
	};

	const handleGetProductInfo = (product: IProduct) => {
		setProductInfo(product);
	};

	const handleGetPlaceInfo = (place: IPlace) => {
		setPlaceInfo(place);
	};

	// autofill external sn and notes field if the params are passed
	const searchParams = useSearchParams();
	useEffect(() => {
		// create temp variables
		let temp_notes, temp_isn, temp_sn, temp_pn;
		if (searchParams.get("notes")) {
			setNotes(searchParams.get("notes") || "");
			temp_notes = searchParams.get("notes");
		}
		if (searchParams.get("item_pn")) {
			setIsn(searchParams.get("item_pn") || "");
			temp_isn = searchParams.get("item_pn");
		}
		if (searchParams.get("sn")) {
			setSn(searchParams.get("sn") || "");
			temp_sn = searchParams.get("sn");
		}
		if (searchParams.get("pn")) {
			setPn(searchParams.get("pn") || "");
			temp_pn = searchParams.get("pn");
		}
		// Check if the "auto-submit" parameter is set to true in the searchParams
		if (searchParams.get("auto-submit") === "true") {
			// Create a message string based on the values of notes, isn, sn, and pn
			let message = "".concat(
				temp_pn ? `Part#: ${temp_pn}, ` : "",
				temp_sn ? `Serial#: ${temp_sn}, ` : "",
				temp_isn ? `Asset#: ${temp_isn}, ` : "",
				temp_notes ? `${temp_notes}` : ""
			);
			if (message.endsWith(", ")) {
				message = message.slice(0, -2);
			}

			// Call the handleAddNotes function with the generated message and type
			handleAddNotes({
				message,
				external_serial_number: temp_isn || "",
			});
		}
	}, [searchParams, handleAddNotes]);

	const assetScanner = useRef<any>(null);

	const handleAssetNoScan = (result: string) => {
		setIsn(result);
	};
	return (
		<>
			{loading && <LinearProgress />}
			<Container>
				<Box py={4}>
					{currentUser && !!licensePlate && (
						<>
							<Grid container>
								<Grid item xs={12} md={4}>
									<Box bgcolor="grey.50" style={{ height: "100%" }}>
										<Box px={2} py={1} bgcolor="grey.200">
											<Grid container spacing={2} alignItems="center">
												<Grid item>
													<Typography variant="h3" style={{ lineHeight: 0 }}>
														<SvgIcon fontSize="inherit">
															<ProductIconSvg />
														</SvgIcon>
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant="h6">
														Product{" "}
														<MomenttrackIdTag id={licensePlateId} type="license_plate" />
													</Typography>
													<Typography variant="caption" color="textSecondary">
														{process.env.REACT_APP_QR_BASENAME || "3hd.us"}/...
														{licensePlateId.slice(-5)}
													</Typography>
												</Grid>
											</Grid>
										</Box>
										<Box p={2}>
											<Grid container spacing={4}>
												<Product
													id={licensePlate.product_id}
													onGetProductInfo={handleGetProductInfo}
												>
													{product => {
														return (
															<>
																<Grid item xs={12} sm={4} md={12}>
																	<Typography variant="overline" color="textSecondary">
																		Part #
																	</Typography>
																	<Typography
																		style={{
																			maxWidth: "100%",
																			wordWrap: "break-word",
																		}}
																		variant="body1"
																	>
																		{product?.part_number || "--"}
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={4} md={12}>
																	<Typography variant="overline" color="textSecondary">
																		Description
																	</Typography>
																	<Typography variant="body1">
																		{product?.description || "--"}
																	</Typography>
																</Grid>
															</>
														);
													}}
												</Product>
												<Grid item xs={12} sm={4} md={12}>
													<Typography variant="overline" color="textSecondary">
														Quantity
													</Typography>
													<Typography variant="body1">{licensePlate.quantity}</Typography>
												</Grid>
												<PlaceEle
													id={licensePlate.location_id}
													onGetPlaceInfo={handleGetPlaceInfo}
												>
													{place => {
														return (
															<>
																<Grid item xs={12} sm={4} md={12}>
																	<Typography variant="overline" color="textSecondary">
																		Currently residing
																	</Typography>
																	<br />
																	<Link
																		href={`/${place?.beacon_id}`}
																		target="_BLANK"
																		variant="body1"
																	>
																		{place?.name || "--"}
																	</Link>
																</Grid>
															</>
														);
													}}
												</PlaceEle>
												<Grid item xs={12} sm={4} md={12}>
													<Typography variant="overline" color="textSecondary">
														Intake date
													</Typography>
													<Typography variant="body1">
														<DateTag date={licensePlate.created_at} dateInputType="utc" />
													</Typography>
												</Grid>
												<Grid item xs={12} sm={4} md={12}>
													<Typography variant="overline" color="textSecondary">
														Asset#
													</Typography>
													<Typography variant="body1">
														{licensePlate.external_serial_number || "--"}
													</Typography>
												</Grid>
											</Grid>
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12} md>
									<Paper square elevation={0} style={{ height: "100%" }}>
										<Box p={2} mb={1}>
											<Typography variant="h5" gutterBottom>
												Link this product
											</Typography>
											<QrCodeInput
												placeholder="Scan/Type QR code..."
												onChange={handleScanToLink}
												disabled={loading}
												autoFocus
											/>
											<ProductLinkDialog
												product={licensePlate}
												place={placeToLink}
												open={!!placeToLink}
												onClose={(ev, reason) =>
													reason !== "backdropClick" && setPlaceToLink(undefined)
												}
												fullWidth
												maxWidth="sm"
											/>
										</Box>
										<form
											onSubmit={async ev => {
												// Create a message string based on the values of notes, isn, sn, and pn
												let message = "".concat(
													pn ? `Part#: ${pn}, ` : "",
													sn ? `Serial#: ${sn}, ` : "",
													isn ? `Asset#: ${isn}, ` : "",
													notes ? `${notes}` : ""
												);
												if (message.endsWith(", ")) {
													message = message.slice(0, -2);
												}
												ev.preventDefault();
												try {
													if (attachmentUploader.current?.hasFile()) {
														await handleAttachment();
														await handleAddNotes({
															message,
															external_serial_number: isn,
														});
														setNotes("");
													} else {
														await handleAddNotes({
															message,
															external_serial_number: isn,
														});
													}
												} catch (error: any) {
													notify(error);
												} finally {
													setPn("");
													setSn("");
													setIsn("");
													setNotes("");
												}
											}}
										>
											<Box style={{ padding: "1rem" }} mb={2}>
												<Typography variant="h5" gutterBottom>
													Update product information.
												</Typography>
												<Typography
													variant="body1"
													color="textPrimary"
													gutterBottom
													style={{ marginBottom: "1rem" }}
												>
													Please enter your serial and part number
												</Typography>

												<Grid container spacing={4}>
													<Grid item xs={12} sm={6}>
														<TextField
															variant="outlined"
															fullWidth
															label="Part#"
															color="secondary"
															value={pn}
															disabled={loading}
															onChange={ev => setPn(ev.target.value)}
															placeholder="Part Number"
															InputLabelProps={{ shrink: true }}
															inputProps={{
																tabIndex: 8,
																pattern: "^\\s*\\S+\\s*$",
															}}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<TextField
															variant="outlined"
															fullWidth
															label="Serial#"
															color="secondary"
															value={sn}
															disabled={loading}
															onChange={ev => setSn(ev.target.value)}
															placeholder="Serial number"
															InputLabelProps={{ shrink: true }}
															inputProps={{ tabIndex: 9 }}
														/>
													</Grid>
												</Grid>
											</Box>
											<Box mb={4} mt={2} style={{ padding: "0 1rem" }}>
												<Typography
													variant="body1"
													style={{ marginBottom: "1rem" }}
													gutterBottom
												>
													Please enter your asset number
												</Typography>
												<TextField
													variant="outlined"
													fullWidth
													label="Asset#"
													color="secondary"
													value={isn}
													onChange={ev => setIsn(ev.target.value)}
													disabled={loading}
													placeholder="Asset number"
													InputLabelProps={{ shrink: true }}
													inputProps={{
														tabIndex: 4,
													}}
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<IconButton
																	edge="start"
																	onClick={() => assetScanner.current?.openScanner()}
																	disabled={loading}
																	tabIndex={1}
																>
																	<Icon>qr_code</Icon>
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
												<QrCodeScanner
													ref={assetScanner}
													hidden
													onResult={handleAssetNoScan}
													disabled={loading}
												/>
											</Box>
											<Box mb={4} px={2}>
												<Typography
													style={{ marginBottom: "1rem" }}
													variant="body1"
													gutterBottom
												>
													Add notes on this product.
												</Typography>
												<Box mb={2}>
													<TextField
														variant="outlined"
														label="Notes"
														value={notes}
														color="secondary"
														helperText={`${notes.length}/512`}
														onChange={ev =>
															setNotes(
																ev.target.value.length <= 512
																	? ev.target.value
																	: ev.target.value.slice(0, 512)
															)
														}
														disabled={loading}
														multiline
														fullWidth
														minRows="6"
														placeholder="Write here..."
														InputLabelProps={{ shrink: true }}
														inputProps={{ tabIndex: 6 }}
													/>
												</Box>
												<Box mb={2}>
													<Typography variant="subtitle2" color="textSecondary" gutterBottom>
														Attachment <i>(Max 100 Mb)</i>:
													</Typography>
													<ClassicUploader
														required={false}
														ref={attachmentUploader}
														targetUrl={process.env.REACT_APP_FILE_UPLOAD_URL as string}
														maxFileSize={1048576 * 100} // 100MB
														query={attachmentsConfig}
													/>
												</Box>

												<Button
													type="submit"
													variant={loading ? "text" : "contained"}
													color="secondary"
													disabled={
														(!pn.trim() && !sn.trim() && !isn.trim() && !notes.trim()) ||
														loading
													}
													tabIndex={7}
												>
													{loading ? "Loading..." : "Submit"}
												</Button>
											</Box>
										</form>
									</Paper>
								</Grid>
							</Grid>
							<Box mt={2}>
								<Paper className="logs-table">
									<LicensePlateActivityLogs
										key={licensePlate.lp_id}
										ref={activityLogs}
										isPublic={false}
										licensePlateId={licensePlate.id}
										onShare={handleShareButton}
										onGetLogs={handleGetLogs}
									/>
								</Paper>
							</Box>
							<EmbeddedContentDialog
								ref={pdfSheetsDialog}
								title="License plate report"
								onCompleted={handleGetDialogContent}
								specificBtn={true}
							>
								<Button component="div" variant="contained" color="secondary">
									<EmailShareButton
										url={pdfURL ? pdfURL : ""}
										disabled={pdfSheetsDialog?.current?.loadingContent}
									>
										Share
									</EmailShareButton>
								</Button>
							</EmbeddedContentDialog>
						</>
					)}

					{!currentUser && !loading && (
						<Box p={2} textAlign="center">
							<Typography gutterBottom>Please login to verify identity.</Typography>
							<Link
								component={RouterLink}
								to={{
									pathname: "/login",
									state: { referrer: currentLocation },
								}}
								underline="none"
							>
								<Button size="large" variant="contained" color="primary">
									Login
								</Button>
							</Link>
						</Box>
					)}
				</Box>
			</Container>
		</>
	);
};

export default LicensePlateTemplate;
